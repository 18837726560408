<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="clearfix">
    <div class="vx-row">

      <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
        <p class="pt-4"> Type prospect <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="prospectType"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="prospectType"
            :options="prospectTypes">
          </v-select>
          <span v-show="errors.has('prospectType')" class="text-danger text-sm" >{{ errors.first('prospectType') }}</span>


          <p v-if="prospectType === 'particular'" class="pt-4"> Type carte <b style="color: #ff6141" >*</b> </p>
          <v-select
          v-if="prospectType === 'particular'"
            v-validate="'required'"
            name="idType"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="idType"
            :options="cartype">
          </v-select>
          <span v-if="prospectType === 'particular'" class="text-danger text-sm" v-show="errors.has('idType')">{{ errors.first('idType') }}</span>

          <div v-if="prospectType === 'corporate'">
            <p > Nom de l'entreprise <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required'"
              name="companyName"
              v-model="companyName"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('companyName')">{{ errors.first('companyName') }}</span>
          </div>

        <p v-if="prospectType === 'corporate'" > NIF <b style="color: #ff6141" >*</b> </p>
          <p v-if="prospectType === 'particular'"> N° <b style="color: #ff6141" >*</b> </p>
          <vs-input
          v-if="prospectType === 'corporate' || prospectType === 'particular'"
            v-validate="'required|max:12'"
            name="cni"
            type="cni"
            v-model="cni"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('cni')">{{ errors.first('cni') }}</span>

        <p v-if="prospectType === 'particular'" > Nom <b style="color: #ff6141" >*</b> </p>
        <vs-input
        v-if="prospectType === 'particular'"
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="last_name"
          v-model="last_name"
          class="w-full" />
        <span v-if="prospectType === 'particular'" class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <p v-if="prospectType === 'particular'" > Prénoms <b style="color: #ff6141" >*</b> </p>
        <vs-input
        v-if="prospectType === 'particular'"
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="first_name"
          v-model="first_name"
          class="w-full" />
        <span v-if="prospectType === 'particular'" class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

        <p v-if="prospectType === 'particular'" > Date de naissance <b style="color: #ff6141" >*</b> </p>
        <p v-if="prospectType === 'corporate'"> Date de création <b style="color: #ff6141" >*</b> </p>
        <datepicker
        v-if="prospectType === 'corporate' || prospectType === 'particular'"
          label-placeholder="Select Date"
          v-model="birthDate"
          v-validate="'required'"
          name="birthDate"
          class="w-full"
          ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('birthDate')">{{ errors.first('birthDate') }}</span>

        <p v-if="prospectType === 'particular'" > Sexe <b style="color: #ff6141" >*</b> </p>
        <v-select
        v-if="prospectType === 'particular'"
          v-validate="'required'"
          name="gender"
          :reduce="rep => rep.key"
          class="w-full" label="name"
          v-model="gender"
          :options="genders">
        </v-select>
        <span v-if="prospectType === 'particular'" class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first('gender') }}</span>

        <p > Nom d'utilisateur <b style="color: #ff6141" >*</b> </p>
        <vs-input
            v-validate="'required'"
            name="username"
            v-model="username"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>

      </div>

      <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-full">
        <p > Nombre de personne <b style="color: #ff6141" >*</b> </p>
        <vs-input-number
          class="w-full "
          v-model="numberOfPerson"
          v-validate="'required'"
          name="numberOfPerson"
          />
        <span class="text-danger text-sm" v-show="errors.has('numberOfPerson')">{{ errors.first('numberOfPerson') }}</span>


        <p > Email <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required|email'"
          data-vv-validate-on="blur"
          name="email"
          type="email"
          v-model="email"
          class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <p  class="pt-4">Téléphone <b style="color: #ff6141" >*</b> </p>
        <vue-tel-input
          v-on:country-changed="countryChanged"
          inputOptions.placeholder="Telephone"
          :selectedCountryCode="true"
          v-model="phone"
          data-vv-validate-on="blur"
          v-validate="'required|min:8|max:16'"
        >
        </vue-tel-input>

        <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>

        <p > Pays <b style="color: #ff6141" >*</b> </p>
        <v-select
          v-validate="'required'"
          name="city"
          :reduce="rep => rep.id"
          class="w-full" label="name"
          v-model="country"
          :options="countries">
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('country')">{{ errors.first('country') }}</span>

        <p > Ville <b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="city"
          v-model="city"
          class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        <p> Preuve<b style="color: #ff6141">*</b>  </p>
            <div class="upload-img mt-2">
              <input  type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
              <span><b style="color:orange">{{ name_file6 }}</b></span>
              <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>

            </div>
      </div>
    </div>

      <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">J'accepte les termes et conditions.</vs-checkbox>
      <vs-button  type="border" to="/pages/login" class="mt-6">Connexion</vs-button>
      <vs-button class="float-right mt-6" @click="Prospect_validate" :disabled="!validateForm">S'inscrire</vs-button>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

// For custom error message
import { Validator } from 'vee-validate'

const dict = {
  custom: {
    cni: {
      required: 'Le champ cni est obligatoire',
      max:'12 caractères maximal'
    },
    username: {
      required: 'Le champ nom d\'utilisateur est obligatoire'
    },
    numberOfPerson: {
      required: 'Le champ nombre de personne est obligatoire'
    },
    idType: {
      required: 'Le champ Type de carte est obligatoire'
    },
    last_name: {
      required: 'Le champ nom est obligatoire'
    },
    first_name: {
      required: 'Le champ prénoms est obligatoire'
    },
    birthDate: {
      required: 'Le champ date est obligatoire'
    },
    gender: {
      required: 'Le champ sexe est obligatoire'
    },
    city: {
      required: 'Le champ ville est obligatoire'
    },
    country: {
      required: 'Le champ pays est obligatoire'
    },
    phone: {
      required: 'Le champ téléphone est obligatoire'
    },
    prospectType: {
      required: 'Le champ type de prospect est obligatoire'
    },
    displayName: {
      required: 'Le champ nom d\'utilisateur est obligatoire'
    },
    email: {
      required: 'Le champ email est obligatoire'
    }
  }
}


// register custom messages
Validator.localize('en', dict)

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      companyName:'',
      idType:'',
      prospectType:'',
      displayName: '',
      email: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      birthDate: '',
      gender: '',
      city: '',
      country: '',
      phone: '',
      username: '',
      cni: '',
      numberOfPerson: '',
      defaultCountryCode: '+228',
      cartype: [
        {
          key: 'passport',
          name: 'Passport'
        },
        {
          key: 'idCard',
          name: 'Carte d\'identité'
        }
      ],
      prospectTypes: [
        {
          key: 'particular',
          name: 'Particulier'
        },
        {
          key: 'corporate',
          name: 'Entreprise'
        }
      ],
      countries: [],
      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      dataImg6:'',
      name_file6:'',
      data_prospect:{},
      // bindProps: {
      //   mode: "international",
      //   placeholder: "Telephone",
      //   required: true,
      //   enabledFlags: true,
      //   autocomplete: "off",
      //   name: "telephone",
      //   maxLen: 16,
      //   validCharactersOnly:true,
      //   inputOptions: {
      //     showDialCode: true
      //   }
      // },

      isTermsConditionAccepted: true
    }
  },
  watch: {
    // country (value) {
    //   if (value) {
    //     this.$http.get(`countries/${value}/`)
    //       .then((response) => {
    //         this.phone = response.data.countryCode
    //       })
    //       .catch(() => {})
    //   }
    // }
  },

  computed: {
    validateForm () {
      return this.isTermsConditionAccepted === true
    }
  },
  methods: {
    updateCurrImg6 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg6 = input.target.files
        this.name_file6 = input.target.files[0].name
      }
    },
    countryChanged (country) {
      this.phone = ''
      const countryCode = country.dialCode
      // this.phone = '+'+country.dialCode
      this.$http.post('code-country/', { countryCode })
        .then((response) => {
          this.country = response.data[0].id
          // console.log("voir le pays",response.data[0].name)
        })
        .catch(() => {})


    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    Prospect_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.registerUserJWt()
        }
      })
    },
    registerUserJWt () {
      this.data_prospect = {}
      /*   if (!this.validateForm || !this.checkLogin()) return */
      this.$vs.loading()
      const formdata = new FormData()

      if (this.prospectType === 'corporate') {
        formdata.append('username', this.username)
        formdata.append('email', this.email)
        formdata.append('phoneNumber', this.phone)
        formdata.append('birthDate', this.$store.state.convertDateTime(this.birthDate).date)
        formdata.append('city', this.city)
        formdata.append('cni', this.cni)
        formdata.append('idType', this.idType)
        formdata.append('companyName', this.companyName)
        formdata.append('prospectType', this.prospectType)
        formdata.append('numberOfPerson', this.numberOfPerson)
        formdata.append('defaultCountryCode', this.defaultCountryCode)
        formdata.append('country', this.country)
        if (this.name_file6) {
          formdata.append('proof', this.dataImg6[0], this.name_file6)
        }

      } else if (this.prospectType === 'particular') {
        formdata.append('username', this.username)
        formdata.append('first_name', this.first_name)
        formdata.append('last_name', this.last_name)
        formdata.append('email', this.email)
        formdata.append('phoneNumber', this.phone)
        formdata.append('birthDate', this.$store.state.convertDateTime(this.birthDate).date)
        formdata.append('gender', this.gender)
        formdata.append('city', this.city)
        formdata.append('cni', this.cni)
        formdata.append('prospectType', this.prospectType)
        formdata.append('idType', this.idType)
        formdata.append('numberOfPerson', this.numberOfPerson)
        formdata.append('defaultCountryCode', this.defaultCountryCode)
        formdata.append('country', this.country)
        if (this.name_file6) {
          formdata.append('proof', this.dataImg6[0], this.name_file6)
        }

      }


      this.$http.post('register/', formdata)
        .then(() => {
          this.$vs.notify({
            title: 'INSCRIPTION',
            text: 'Vous vous êtes inscrit avec succès.',
            color: 'success',
            position:'top-center'
          })
          // window.getPrendTaCom.success('Vous vous êtes inscrit avec succès.', response)
          this.$router.push('/pages/login').catch(() => {})
          this.$vs.loading.close()
        })
        .catch((error) => {
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]

            if (item === 'username') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Nom d\'utilisateur ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'phoneNumber') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Téléphone vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'email') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'L\'Email déjà utilisé ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'password') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Mot de passe',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'password2') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Confirmer le mot de passe',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'first_name') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Prénoms vide ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'last_name') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Nom vide ',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'birthDate') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Date de naissance vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'gender') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Sexe vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'city') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Champ Ville vide',
                color: 'warning',
                position:'top-center'
              })

            }

          }
          this.$vs.notify({
            time:8000,
            title: 'ENREGISTREMENT',
            text: 'L\'enregistrement a échoué',
            color: 'danger',
            position:'top-right'
          })
          this.$vs.loading.close()
        })
    },
    getAllContries () {
      delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    this.getAllContries()
  }
}
</script>
